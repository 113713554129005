<div #originRef
     class="flex items-center rounded py-1 pr-1 pl-3 "
     matRipple>
     <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary">
      <mat-icon [icIcon]="icPerson"></mat-icon>
    </div>
  <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3" fxHide.xs>{{userName | uppercase}}</div>
  <div>
    <button>
      <a (click)="close()" [routerLink]="['/login']" color="primary" mat-button>LOGOUT</a>
    </button>
  </div>

</div>
