<ng-template #sidenavRef>
    <app-sidenav [collapsed]="sidenavCollapsed$ | async" [storeDetails] ="storeList"></app-sidenav>
  </ng-template>

  <ng-template #toolbarRef>
    <app-toolbar [hasShadow]="toolbarShadowEnabled$ | async"
                 [mobileQuery]="!(isDesktop$ | async)"
                 [farmDetails] ="farmList"
                 [user] = "users"
                 [loginType]="loginType"
                 class="vex-toolbar"></app-toolbar>
  </ng-template>

  <ng-template #footerRef>
    <vex-footer *ngIf="isFooterVisible$ | async" class="vex-footer"></vex-footer>
  </ng-template>

  <ng-template #quickpanelRef>
    <vex-quickpanel></vex-quickpanel>
  </ng-template>

   <app-layout2 [footerRef]="footerRef"
              [quickpanelRef]="quickpanelRef"
              [sidenavRef]="sidenavRef"
              [toolbarRef]="toolbarRef"></app-layout2>

  <!-- <vex-config-panel-toggle (openConfig)="configpanel.open()"></vex-config-panel-toggle> -->

  <!-- CONFIGPANEL -->
  <!-- <vex-sidebar #configpanel [invisibleBackdrop]="true" position="right">
    <vex-config-panel></vex-config-panel>
  </vex-sidebar> -->
  <!-- END CONFIGPANEL -->

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#1459cd" type="ball-spin-clockwise" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>


  