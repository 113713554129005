import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import icPerson from '@iconify/icons-ic/twotone-person';
import { User } from 'src/app/account-management/model/user';
import { RsaService } from 'src/app/shared/service/rsa.service';
import { LoginService } from 'src/app/auth/service/login.service';
import { PopoverRef } from '../../../components/popover/popover-ref';
import { Router } from '@angular/router';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

  dropdownOpen: boolean;
  @Input() users: User;
  public userName:string;
  icPerson = icPerson;
  public user: any;

  constructor(private popover: PopoverService,  private rsaService: RsaService, private router: Router,
              private cd: ChangeDetectorRef, private loginService : LoginService,
              // private popoverRef: PopoverRef<ToolbarUserComponent>
              ) { }

  ngOnInit() {
    this.users = JSON.parse(this.rsaService.decrypt(localStorage.getItem('4')));
    console.log('ddd',this.users);
    if(!this.users.userName){
      this.userName=this.users.farmCircleName;
    }else{
      this.userName=this.users.userName;
    }

    // this.user = this.popoverRef?.data;
  }

  showPopover(originRef: HTMLElement) {
    // this.dropdownOpen = true;
    // this.cd.markForCheck();

    // const popoverRef = this.popover.open({
    //   content: ToolbarUserDropdownComponent,
    //   origin: originRef,
    //   offsetY: 12,
    //   data: this.users,
    //   position: [
    //     {
    //       originX: 'center',
    //       originY: 'top',
    //       overlayX: 'center',
    //       overlayY: 'bottom'
    //     },
    //     {
    //       originX: 'end',
    //       originY: 'bottom',
    //       overlayX: 'end',
    //       overlayY: 'top',
    //     },
    //   ]
    // });

    // popoverRef.afterClosed$.subscribe(() => {
    //   this.dropdownOpen = false;
    //   this.cd.markForCheck();
    // });
  }
  close() {
    console.log("here logout");
    this.loginService.logout().subscribe((data :any) =>{
    }, err =>{
      console.log(err)
    })

    // this.popoverRef.close();
    this.router.navigateByUrl('');
    localStorage.clear();
  }
}
