import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { FarmLayoutComponent } from './farm-layout/farm-layout.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfigPanelModule } from 'src/@vex/components/config-panel/config-panel.module';
import { SidebarModule } from 'src/@vex/components/sidebar/sidebar.module';
import { FooterModule } from 'src/@vex/layout/footer/footer.module';
import { LayoutModule } from 'src/@vex/layout/layout.module';
import { QuickpanelModule } from 'src/@vex/layout/quickpanel/quickpanel.module';
import { SidenavModule } from 'src/@vex/layout/sidenav/sidenav.module';
import { ToolbarModule } from 'src/@vex/layout/toolbar/toolbar.module';
import { Layout2Module } from '../layout/layout2.module';
import { MaterialModule } from '../material/material.module';


@NgModule({
  declarations: [FarmLayoutComponent],
  imports: [
    CommonModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    FooterModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule,
    MaterialModule,
    Layout2Module,
    NgxSpinnerModule
  ]
})
export class FarmLayoutModule { }
