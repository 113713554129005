<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>
  <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 block" fxLayout="row"
    fxLayoutAlign="start center">
    <img class="w-8 select-none" src="assets/img/demo/logo.svg">
    <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">Farm2Bag</h1>
  </a>
  <h2>{{farmDetails?.farmName}} <span class="text-primary">(Farm Hub Code - {{farmDetails?.farmCode}})</span>  </h2>

  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async" [fxHide]="mobileQuery" class="px-gutter"
    fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
    <app-navigation-item *ngFor="let item of navigationItems" [item]="item"></app-navigation-item>
  </div>

  <span fxFlex></span>

  <div class="-mx-1 flex items-center">
    <!-- <div class="px-1">
      <button  mat-icon-button type="button">
        <mat-icon [icIcon]="star"></mat-icon>
      </button>
    </div>

    <div class="px-1">
      <button  mat-icon-button type="button">
        <mat-icon [icIcon]="icCart"></mat-icon>
      </button>
    </div>
    <div class="px-1">
      <button  mat-icon-button type="button">
        <mat-icon [icIcon]="icNotificationsActive"></mat-icon>
      </button>
    </div> -->
    <div class="px-1">
      <app-toolbar-user [loginType]="loginType" [users]="farmDetails"></app-toolbar-user>
    </div>
  </div>
  <h4 class="-mx-1 flex items-center" style="margin-left: 5px;">v-0.1</h4>
</div>

<app-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async" [fxHide]="mobileQuery">
</app-navigation>
