import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from 'src/@vex/vex.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHtppInterceptorServiceService } from './shared/service/auth-htpp-interceptor-service.service';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { FarmLayoutModule } from './farm-layout/farm-layout.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    VexModule,
    HttpClientModule,
    CustomLayoutModule,
    FarmLayoutModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthHtppInterceptorServiceService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
